import dynamic from 'next/dynamic'
import React from 'react'

const Hero = dynamic(() => import('./Hero'))
const ServicesSection = dynamic(() => import('./ServicesSection'))
const RecommendedSection = dynamic(() => import('./RecommendedSection'))
const StepsSection = dynamic(() => import('./StepsSection'))
const Reviews = dynamic(() => import('./Reviews'))
const CTASection = dynamic(() => import('./CTASection'))
const About = dynamic(() => import('./About'))

function LandingPage() {
  return (
    <>
      <Hero />
      <ServicesSection />
      <RecommendedSection />
      <About />
      <StepsSection />
      <Reviews />
      <CTASection />
    </>
  )
}

export default React.memo(LandingPage)
